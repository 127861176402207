import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config.tsx";
import { checkForAdmin } from "./helpers/requests.tsx";
import { useAuth } from "../firebase/auth.tsx";
import { useTranslation } from "react-i18next";

import "../App.css";

const PageMenu = ({
  userId,
  userRole,
  firstName,
  lastName,
  email,
  headquarterId,
  groupId,
  logout,
  fullScreenMode,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHeadquarterAdmin, setIsHeadquarterAdmin] = useState(false);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const { changeLanguage } = useAuth();

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const languageImages = {
    en: "/images/flags/English.png",
    de: "/images/flags/German.png",
    cz: "/images/flags/Czech.png",
  };

  // Preload language images
  useEffect(() => {
    Object.values(languageImages).forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsLanguageMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!email || !userRole) {
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      const result = await checkForAdmin(userId);

      if (result.admin) setIsAdmin(result.admin);
      else if (result.headquarterAdmin)
        setIsHeadquarterAdmin(result.headquarterAdmin);
      else if (result.groupAdmin) setIsGroupAdmin(result.groupAdmin);
    };

    fetchData();
  }, []);

  const handleStreamClick = () => {
    // Force a reload to the stream route
    navigate(`/stream/${userId}`, { replace: true });
    window.location.reload();
  };

  const handleLanguageClick = (newLanguage) => {
    toggleLanguageMenu();
    changeLanguage(newLanguage);
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen((prev) => !prev);
  };

  const handleQuestionClick = () => {
    let link;
    if (isHeadquarterAdmin) {
      if (i18n.language === "cz")
        link = "https://www.youtube.com/playlist?list=PLNmM6qyy6Z7A3jppAbeZjZMXBWb6hcYdi";
      else if (i18n.language === "en")
        link = "https://www.youtube.com/playlist?list=PLNmM6qyy6Z7Bu30fRxl87uCmyxM91hI1p";
    }
    else if (isGroupAdmin) {
      if (i18n.language === "cz")
        link = "https://www.youtube.com/playlist?list=PLNmM6qyy6Z7BtiaY2TuFqje2yE4SXzL8m";
      else if (i18n.language === "en")
        link = "https://www.youtube.com/playlist?list=PLNmM6qyy6Z7BMVLlkQasJZOzlaLM00L3T";
    }
    else {
      if (i18n.language === "cz")
        link = "https://www.youtube.com/playlist?list=PLNmM6qyy6Z7Au92hglmkpeewQ7ayw-jZN";
      else if (i18n.language === "en")
        link = "https://www.youtube.com/playlist?list=PLNmM6qyy6Z7AMcPaXU5IWDqdJySuohcFl";
    }

    window.open(link, "_blank");
  };

  return (
    <div>
      {!fullScreenMode && (
        <div
          style={{
            backgroundColor: "#39A686",
            borderRadius: "10px",
            marginTop: "16px",
            padding: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
            top: "0",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: "11",
            width: "96%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/images/VR_ICU_Logo_Small.png"
              alt="Logo"
              style={{
                width: "8vh",
                minWidth: "50px",
                marginLeft: "10px",
              }}
            />
            <Link
              to="/main"
              style={{
                color: "white",
                marginLeft: "50px",
                textDecoration: "none",
              }}
            >
              {t("training_reports")}
            </Link>
            <a
              onClick={handleStreamClick}
              style={{
                color: "white",
                marginLeft: "50px",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {t("stream")}
            </a>
            {isAdmin && (
              <>
                <span
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "white",
                    marginLeft: "45px",
                  }}
                ></span>
                <Link
                  to="/headquarterList"
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  {t("headquarter_list")}
                </Link>
                <Link
                  to="/groups"
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  {t("hospital_list")}
                </Link>
                <Link
                  to="/allUsers"
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  {t("user_list")}
                </Link>
              </>
            )}
            {isGroupAdmin && (
              <>
                <div
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "white",
                    marginLeft: "45px",
                  }}
                ></div>
                <Link
                  to={`/userList/group/${groupId}`}
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  {t("Hospital")}
                </Link>
              </>
            )}
            {isHeadquarterAdmin && (
              <>
                <div
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "white",
                    marginLeft: "45px",
                  }}
                ></div>
                <Link
                  to={`/groupList/headquarter/${headquarterId}`}
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  {t("Headquarter")}
                </Link>
              </>
            )}
            {userRole !== "admin" && ["cz", "en"].includes(i18n.language) && (
              <>
                <img
                  src="/images/question.png"
                  alt="Logo"
                  title={t("How_to_use_this_site")}
                  style={{
                    width: "26px",
                    marginLeft: "40px",
                    cursor: "pointer",
                  }}
                  onClick={handleQuestionClick}
                />
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginRight: "10px",
                color: "white",
                lineHeight: "1",
              }}
            >
              <div
                style={{
                  textAlign: "right",
                  paddingBottom: "3px",
                }}
              >
                {firstName} {lastName}
              </div>
              <div
                style={{
                  textAlign: "right",
                  fontFamily: "VirtualLabQuickSandLight",
                }}
              >
                {email}
              </div>
            </div>
            <img
              src="/images/Icon_User.png"
              alt="Logo"
              style={{
                width: "8%",
                marginRight: "10px",
              }}
            />
            <div style={{ position: "relative" }} ref={dropdownRef}>
              <button
                onClick={toggleLanguageMenu}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <img
                  src={`/images/flags/${
                    i18n.language === "de"
                      ? "German.png"
                      : i18n.language === "cz"
                      ? "Czech.png"
                      : "English.png"
                  }`}
                  alt="Language"
                  style={{ width: "30px", height: "20px" }}
                />
              </button>
              {isLanguageMenuOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "30px",
                    marginLeft: "-4px",
                    background: "#cccccc",
                    boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    zIndex: "20",
                  }}
                >
                  {Object.entries(languageImages).map(([lang, src]) => (
                    <div
                      key={lang}
                      onClick={() => handleLanguageClick(lang)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={src}
                        alt={lang}
                        style={{ width: "30px", height: "20px" }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              onClick={logout}
              style={{
                backgroundColor: "transparent",
                marginRight: "20px",
                border: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              {t("log_out")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageMenu;
